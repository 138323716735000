import { useParams } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import Button from 'src/components/Library/Button/Button'
import TheHubIcon from 'src/lib/icons/TheHubIcon/TheHubIcon'

const PasswordResetConfirmationPage = () => {
  const { resetLink } = useParams()

  return (
    <>
      <Metadata
        title="Confirm Password Reset"
        description="Confirm Password Reset"
      />
      <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-gray-50">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="space-x-2 justify-center">
            <TheHubIcon />
          </div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Confirm password reset request
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Button onClick={() => (window.location.href = resetLink)}>
              Reset now
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PasswordResetConfirmationPage
